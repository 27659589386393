import Vue from "vue";
import VueRouter from "vue-router";
import Home from '@/views/Home';
// import infoview from '@/views/infoview';


Vue.use(VueRouter);

const routes = [
  {
    name: "Home",
    path: "/:name",
    component: Home
  },

  // {
  //   name: "Info",
  //   path: "/info",
  //   component: infoview
  // },

  { path: '*', 
  beforeEnter () {
    window.location = "https://web.ebooking.today/"
  }
}

];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
