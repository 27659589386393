<template>
  <div>
    <v-snackbar
      :timeout="timeout"
      :value="snackbar"
      fixed
      center
      shaped
      :color="stepButtonColor"
    >
      Booked successfully!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row class="mb-10">
      <v-col cols="12" md="3"></v-col>

      <v-col cols="12" md="6" class="mb-16">
        <v-stepper v-model="e6" vertical class="mx-3 grey lighten-5">
          <v-stepper-step :complete="e6 > 1" step="1" :color="stepButtonColor">
            <span class="font-weight-bold">Choose a Branch</span>
            <span class="font-weight-light" v-if="selectedBranch">
              ( {{ selectedBranch.name }} )
            </span>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-select
              :items="items"
              item-text="name"
              item-value="branch_id"
              label="Branch"
              :loading="branchLoading"
              v-model="selectedBranch"
              @change="
                (branchLoading = true),
                  getBranchDateAndTime(),
                  findMaxPerson(),
                  getService(),
                  (selectedPerson = ''),
                  (selectedDated = {}),
                  (selectedTime = true),
                  (snackbar = false)
              "
              return-object
            >
            </v-select>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2" :color="stepButtonColor">
            <span class="font-weight-bold">Booking details</span>
            <span
              class="font-weight-light"
              v-if="
                selectedPerson &&
                selectedDated.selectedDate &&
                selectedTime != true
              "
            >
              ( {{ selectedPerson }} Person, {{ selectedDated.selectedDate }},
              {{ selectedTime }} )
            </span>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  class="rounded-lg"
                  dense
                  outlined
                  label="Total Person"
                  prepend-inner-icon="mdi-account"
                  v-model="selectedPerson"
                  :error-messages="selectedPersonErrors"
                  required
                  @input="$v.selectedPerson.$touch()"
                  @blur="$v.selectedPerson.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="selectedPerson && !$v.$invalid">
                <FunctionalCalendar
                  v-model="selectedDated"
                  :disabled-day-names="weekdays"
                  :disabledDates="offday"
                  :limits="{ min: today, max: '01/01/2200' }"
                  :date-format="'dd/mm/yyyy'"
                  v-if="check"
                  :hidden-elements="['leftAndRightDays']"
                  :is-date-picker="true"
                  class="elevation-0"
                  v-on:choseDay="this.getBooking"
                ></FunctionalCalendar>
              </v-col>
            </v-row>
            <div class="d-flex justify-center mt-16">
              <v-progress-circular
                :size="50"
                :color="stepButtonColor"
                indeterminate
                v-if="showTime"
              ></v-progress-circular>
            </div>
            <v-row
              class="mb-2"
              v-if="
                selectedPerson &&
                selectedDated.selectedDate &&
                this.showTime == false
              "
            >
              <v-col cols="4" sm="12">
                <p>Morning</p>
                <v-row>
                  <div v-for="(time, i) in timesPlusDuration" :key="i">
                    <v-btn
                      class="mb-2 mt-1 mr-1 ml-3"
                      outlined
                      color="indigo"
                      small
                      v-model="selectedTime"
                      @click="
                        selectedTime = time;
                        e6 = 3;
                      "
                      v-if="parseFloat(time.substring(0, 2)) < 12"
                    >
                      {{ time }}
                    </v-btn>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="4" sm="12">
                <p>Noon</p>
                <v-row>
                  <div v-for="(time, i) in timesPlusDuration" :key="i">
                    <v-btn
                      class="mb-2 mt-1 mr-1 ml-3"
                      outlined
                      color="indigo"
                      small
                      v-model="selectedTime"
                      @click="
                        selectedTime = time;
                        e6 = 3;
                      "
                      v-if="
                        parseFloat(time.substring(0, 2)) >= 12 &&
                        parseFloat(time.substring(0, 2)) <= 18
                      "
                    >
                      {{ time }}
                    </v-btn>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="4" sm="12">
                <p>Night</p>
                <v-row>
                  <div v-for="(time, i) in timesPlusDuration" :key="i">
                    <v-btn
                      class="mb-2 mt-1 mr-1 ml-3"
                      outlined
                      color="indigo"
                      small
                      v-model="selectedTime"
                      @click="
                        selectedTime = time;
                        e6 = 3;
                      "
                      v-if="parseFloat(time.substring(0, 2)) > 18"
                    >
                      {{ time }}
                    </v-btn>
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <br />
            <br />

            <v-btn
              text
              :color="continueButtonColor"
              outlined
              @click="
                (e6 = 1),
                  (selectedPerson = ''),
                  (selectedDated = {}),
                  (selectedTime = true),
                  (selectedBranch = '');
                $v.$reset();
              "
            >
              Back
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3" :color="stepButtonColor">
            <span class="font-weight-bold">Your info</span>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card-title>
              <v-row>
                <v-icon>mdi-account</v-icon>
                <span>Customer Information</span>
              </v-row>
            </v-card-title>

            <v-form class="mb-2" ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <vue-phone-number-input
                    size="lg"
                    default-country-code="MY"
                    v-model="phoneNumber"
                    :rules="phoneRules"
                    :error="checkTelInput"
                    valid-color="#919191"
                    @update="results = $event"
                  ></vue-phone-number-input>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Name"
                    v-model="fullname"
                    :rules="fullnameRules"
                    required
                    v-if="results.isValid"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    hint="example@hotmail.com"
                    required
                    v-if="results.isValid"
                  ></v-text-field>
                </v-col>

                <!-- addon -->
                <v-col cols="12" sm="5">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-if="results.isValid"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="birthday"
                        label="Birthday(optional)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="birthday"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="address"
                    label="Address(optional)"
                    v-if="results.isValid"
                  ></v-text-field>
                </v-col>

                <!-- end -->
                <v-col>
                  <v-textarea
                    clearable
                    counter
                    v-model="remark"
                    clear-icon="mdi-close-circle"
                    label="Remark(Optional)"
                    :rules="remarkRules"
                    v-if="results.isValid"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>

            <v-btn :color="continueButtonColor" outlined @click="validate()">
              Continue
            </v-btn>
            <v-btn text @click="e6 = 2"> Back </v-btn>
          </v-stepper-content>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title class="grey lighten-4">
                <v-container>
                  <v-icon class="light-green--text"
                    >mdi-check-circle-outline</v-icon
                  >Comfirm your booking
                </v-container>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="6" md="4">
                      <h5>Branch:</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <p>{{ selectedBranch.name }}</p>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="6" md="4">
                      <h5>Date and Time:</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <p>{{ selectedDated.selectedDate }},{{ selectedTime }}</p>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="6" md="4">
                      <h5>Total Person:</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <p>{{ selectedPerson }}</p>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="6" md="4">
                      <h5>Your Info:</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <p>
                        {{ fullname }} <br />{{ email }}<br />{{ phoneNumber }}
                        <br />
                       <span v-if="address!=''">{{ address }}<br /></span>
                       <span v-if="birthday!=''"> {{ birthday }} (Birthday) </span>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="6" md="4">
                      <h5>Remark:</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <p>{{ remark }}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="(dialog = false), createCustomer()"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-stepper>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-lg-block"></v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import FunctionalCalendar from "vue-functional-calendar";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.use(FunctionalCalendar, {
  dayNames: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  isAutoCloseable: true,
});

export default {
  mixins: [validationMixin],

  data: () => ({
    domain: BASEURL,
    e6: 1,
    items: [],
    dialog: false,
    email: "",
    fullname: "",
    phoneNumber: "",
    errors: "",
    selectedTime: true,
    selectedBranch: "",
    selectedPerson: "",
    selectedDated: {},
    remarkRules: [(v) => v.length <= 200 || "Max 30  characters"],
    fullnameRules: [(v) => !!v || "Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => !(/[ ]/.test(v)) || 'E-mail cannot content spaces',

    ],
    phoneRules: [(v) => !!v || "Phone is required"],
    // birthdayRules: [(v) => !!v || "Birthday is required"],
    // addressRules: [(v) => !!v || "Address is required"],
    valid: true,
    remark: "none",
    stepButtonColor: "",
    continueButtonColor: "",
    company_id: "",
    branch_id: "",
    workingDays: "",
    holiday: [],
    workingTime: "",
    branchLoading: false,
    table: [],
    check: false,
    serviceID: "",
    booking: [],
    serviceDuration: "",
    slot: "",
    customerID: "",
    contact: "",
    results: "",
    checkTelInput: false,
    maxPerson: "",
    showTime: false,
    timeout: 2000,
    snackbar: false,
    timesPlusDuration: [],
    serviceTitle: "",
    serviceDescription: "",
    merchantPhone: "",
    whatsappRedirect: 0,
    branchID: "",
    existCustomer: "",
    url: "",
    menu2: false,
    birthday: "",
    address: "",
  }),

  validations: {
    selectedPerson: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue(value) {
        return maxValue(this.maxPerson)(value);
      },
    },
  },
  computed: {
    selectedPersonErrors() {
      const errors = [];
      if (!this.$v.selectedPerson.$dirty) return errors;
      !this.$v.selectedPerson.numeric &&
        errors.push("Person must consist only number");
      !this.$v.selectedPerson.minValue && errors.push("At least 1 person");
      !this.$v.selectedPerson.maxValue &&
        errors.push("Maximum " + this.maxPerson + " Person ");
      !this.$v.selectedPerson.required && errors.push("Person is required");
      return errors;
    },
    progress() {
      return Math.min(100, this.value.length * 10);
    },
    color() {
      return ["error", "warning", "success"][Math.floor(this.progress / 40)];
    },
    weekdays() {
      var value = [];

      if (this.workingDays[0] == 1) {
        value.push("Su");
      }
      if (this.workingDays[1] == 1) {
        value.push("Mo");
      }
      if (this.workingDays[2] == 1) {
        value.push("Tu");
      }
      if (this.workingDays[3] == 1) {
        value.push("We");
      }
      if (this.workingDays[4] == 1) {
        value.push("Th");
      }
      if (this.workingDays[5] == 1) {
        value.push("Fr");
      }
      if (this.workingDays[6] == 1) {
        value.push("Sa");
      }

      return value;
    },
    offday() {
      var value = [];
      for (var i = 0; i < this.holiday.length; i++) {
        value.push(this.holiday[i]);
      }
      return value;
    },
    start() {
      var start = JSON.stringify(this.workingTime[0]);

      return start;
    },
    end() {
      var end = this.workingTime[1];

      return end;
    },
    today() {
      var d = new Date();
      var datestring =
        ("0" + d.getDate()).slice(-2) +
        "/" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        d.getFullYear();
      return datestring;
    },
    timeSession() {
      this.setShowTimeToTrue();
      var moment = require("moment"); // require
      moment().format();
      var currentTime = moment(new Date(), "hmm").format("HH:mm");
      var currentDay = moment(new Date(), "ddmmyy").format("D/M/YYYY");
      var startTime = this.start;
      var interval = this.gap;
      var times = [];
      var period = "m";
      var periodsInADay = moment.duration(1, "day").as(period);
      var startTimeMoment = moment(startTime, "HH:mm");
      var endTime = this.end;
      var endTimeMoment = moment(endTime, "HH:mm");

      for (let i = 0; i <= periodsInADay; i += interval) {
        var time = startTimeMoment.add(i === 0 ? 0 : interval, period);

        if (this.selectedDated.selectedDate == currentDay) {
          if (time.format("HH:mm") > currentTime && time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        } else {
          if (time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        }
      }

      return times;
    },
    

    person() {
      var people = [];
      for (let i = 1; i <= this.maxPerson; i++) {
        people.push(i);
      }
      return people;
    },
  },
  created() {
    this.url = this.$route.params.name;
    this.findCompanyByName();
  },

  watch: {
    selectedPerson() {
      this.getService();
      this.selectedDated = {};
      this.selectedTime = true;
    },
    phoneNumber() {
      if (this.results.isValid == true) {
        this.checkTelInput = false;
        this.getCustomerInfo();
      } else if (this.results.isValid == false) {
        this.checkTelInput = true;
      }
    },
  },
  methods: {
    validate() {
      var mailformat =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.results.isValid == true) {
        if (
          this.fullname != "" &&
          this.email != "" &&
          // this.birthday != "" &&
          // this.address != "" &&
          this.email.match(mailformat)
        ) {
          this.dialog = true;
        } else {
          this.$refs.form.validate();
        }
      } else {
        this.checkTelInput = true;
      }
    },

    findCompanyByName() {
      const params = new URLSearchParams();
      params.append("getCompanyByURL", "done");
      params.append("url", this.url);
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.company_id = response.data.company[0].company_id;

            this.continueButtonColor = JSON.parse(
              response.data.company[0].color
            ).continueButtonColor;
            this.stepButtonColor = JSON.parse(
              response.data.company[0].color
            ).stepButtonColor;

            this.getBranchName();
          } else {
            window.location.replace("https://web.ebooking.today/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranchName() {
      const params = new URLSearchParams();
      params.append("get", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.items = response.data.branch;
          } else {
            console.log("no branch");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBranchDateAndTime() {
      this.check = false;
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].branch_id == this.selectedBranch.branch_id) {
          this.workingDays = JSON.parse(this.items[i].working_day);
          this.workingTime = JSON.parse(this.items[i].working_time);
          this.gap = JSON.parse(this.items[i].gap);
          this.merchantPhone = this.items[i].phone_number;
          this.whatsappRedirect = this.items[i].redirect;
        }
      }
      this.getBranchHoliday();
    },
    getBranchHoliday() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectedBranch.branch_id);
      axios({
        method: "post",
        url: this.domain + "/holiday/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.holiday = JSON.parse(response.data.holiday[0].date);
            this.check = true;
            this.e6 = 2;
            this.branchLoading = false;
          } else {
            console.log("no holiday");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getService() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectedBranch.branch_id);
      params.append("seat", this.selectedPerson);
      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.table = response.data.service[0];
            this.serviceID = this.table.service_id;
            this.serviceDuration = this.table.duration;
            this.slot = this.table.slot;
            this.serviceDescription = this.table.description;
            this.serviceTitle = this.table.title;
            this.getBooking();
          } else {
            console.log("no service");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBooking() {
      if (this.selectedPerson == "" || this.selectedDated == "") {
        return;
      }
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("selected_date", this.selectedDated.selectedDate);
      params.append("service_id", this.serviceID);

      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.booking = response.data.booking;
            this.finalTime();
          } else {
            console.log("no booking");
            this.booking = [];
            this.finalTime();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCustomer() {
      if (this.existCustomer == "") {
        const params = new URLSearchParams();
        params.append("create", "done");
        params.append("name", this.fullname);
        params.append("contact", "0" + this.results.nationalNumber);
        params.append("email", this.email);
        params.append("birthday", this.birthday);
        params.append("address", this.address);
        params.append("company_id", this.company_id);

        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              console.log("Add customer successfully");
              this.customerID = response.data.customer;
              this.createBooking();
            } else {
              console.log("Please check again your info");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.customerID = this.existCustomer;
        this.createBooking();
      }
    },
    createBooking() {
      if (this.remark == "" || this.remark == null) {
        this.remark = "none";
      }

      this.fullname = "";
      this.email = "";
      this.birthday = "";
      this.address = "";
      const params = new URLSearchParams();
      params.append("create", "done");
      params.append("service_id", this.serviceID);
      params.append("selected_time", this.selectedTime);
      params.append("duration", this.serviceDuration);
      params.append("service_title", this.serviceTitle);
      params.append("service_description", this.serviceDescription);
      params.append("selected_date", this.selectedDated.selectedDate);
      params.append("person", this.selectedPerson);
      params.append("customer_id", this.customerID);
      params.append("remark", this.remark);
      params.append("branch_id", this.selectedBranch.branch_id);

      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Booking successfully");
            this.bookingID = response.data.booking;
            this.messageToCustomer();
            this.messageToMerchant();
            this.emailToCustomer();
            this.emailToMerchant();
            this.whatsappMerchant();

            this.selectedBranch = "";
            this.selectedPerson = "";
            this.selectedDated = {};
            this.phoneNumber = "";
            this.results = {};
            this.$v.$reset();
          } else {
            console.log("Booking failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findMaxPerson() {
      const params = new URLSearchParams();
      params.append("findMaxPerson", "done");
      params.append("branch_id", this.selectedBranch.branch_id);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.maxPerson = response.data.service[0].seat;
          } else {
            console.log("Booking failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCustomerInfo() {
      const params = new URLSearchParams();
      params.append("getCustomerInfo", "done");
      params.append("contact", "0" + this.results.nationalNumber);
      params.append("company_id", this.company_id);
      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.fullname = response.data.customer[0].name;
            this.email = response.data.customer[0].email;
            this.existCustomer = response.data.customer[0].customer_id;
            this.address = response.data.customer[0].address;
            this.birthday = response.data.customer[0].birthday;
          } else {
            this.$refs.form.resetValidation();
            this.fullname = "";
            this.email = "";
            this.existCustomer = "";
            this.address = "";
            this.birthday = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setShowTimeToTrue() {
      this.showTime = true;
    },
    setShowTimeToFalse() {
      this.showTime = false;
    },
    finalTime() {
      var moment = require("moment"); // require
      moment().format();
      var duration = this.serviceDuration;
      this.timesPlusDuration = [];
      var first = this.timeSession;
      var period = "m";
      // var second = [];
      for (let i = 0; i < first.length; i++) {
        var start = moment(first[i], "HH:mm");
        var s = moment(first[i], "HH:mm");
        var end = s.add(duration, period);
        var slot = 0;
        for (let j = 0; j < this.booking.length; j++) {
          var bookingStart = moment(this.booking[j].selected_time, "HH:mm");
          var bookings = moment(this.booking[j].selected_time, "HH:mm");
          var bookingEnd = bookings.add(this.booking[j].duration, period);

          if (bookingStart >= start && bookingStart < end) {
            slot += 1;
            continue;
          }
          if (bookingEnd > start && bookingEnd <= end) {
            slot += 1;
            continue;
          }
          if (bookingStart < start && bookingEnd > end) {
            slot += 1;
            continue;
          }
        }

        if (slot < this.slot) {
          this.timesPlusDuration.push(start.format("HH:mm"));
        }
      }
      this.setShowTimeToFalse();
    },
    whatsappMerchant() {
      if (this.whatsappRedirect == 1) {
        window.location.href =
          "https://api.whatsapp.com/send?phone=+6" +
          this.merchantPhone +
          "&text=My%20Appointment%20ID:%20" +
          this.bookingID +
          "%0AName:%20" +
          this.fullname +
          "%0ADate:%20" +
          this.selectedDated.selectedDate +
          "%0ATime:%20" +
          this.selectedTime +
          "%0ARemark:%20" +
          this.remark +
          "%0AMy%20Appointment%20Detail:%0A" +
          "https://info.ebooking.today/?id=" +
          this.bookingID;
      } else if (this.whatsappRedirect == 0) {
        this.snackbar = true;
        this.e6 = 1;
      }
    },
    messageToCustomer() {
      const params = new URLSearchParams();
      params.append("booking_id", this.bookingID);
      params.append("message_customer", "done");

      axios({
        method: "post",
        url: "https://ebooking.today/reminder/whatsapp/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    messageToMerchant() {
      const params = new URLSearchParams();
      params.append("booking_id", this.bookingID);
      params.append("message_merchant", "done");

      axios({
        method: "post",
        url: "https://ebooking.today/reminder/whatsapp/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    emailToCustomer() {
      const params = new URLSearchParams();
      params.append("booking_id", this.bookingID);
      params.append("email_customer", "done");
      axios({
        method: "post",
        url: this.domain + "/email/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    emailToMerchant() {
      const params = new URLSearchParams();
      params.append("booking_id", this.bookingID);
      params.append("email_merchant", "done");
      axios({
        method: "post",
        url: this.domain + "/email/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="postcss">
.vfc-week .vfc-day span.vfc-span-day.vfc-cursor-not-allowed {
  pointer-events: none !important;
}
.vfc-single-input {
  font-size: inherit;
  -webkit-transition: width 200ms;
  transition: width 200ms;
  padding: 7px !important;
  width: 100% !important;
  margin: 0px, 0px, 8px;
  color: #9b9b9b !important;
  border: 1px solid #9b9b9b;
  text-align: center;
  outline: none;
}
</style>