<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
  computed: {},
  created() {

  },
  watch: {},
  methods: {},
};
</script>
