<template>
  <v-app>
    <v-main>
      <div
        :style="{
          backgroundImage: createBackgroundString,
          height: backgroundHeight,
        }"
      >
        <v-row class="pt-2 pt-md-5">
          <v-col cols="8" md="6" align="center">
            <h3
              :style="{ color: headerTextColor }"
              class="ml-3 text-left text-md-center"
            >
              <v-avatar size="30" class="mr-2">
                <img :src="imagePath" alt="company_pic" /> </v-avatar
              >{{ company_name }}
            </h3>
          </v-col>
          <v-col cols="4" md="6" align="center">
            <a
              :style="{ color: headerTextColor }"
              :href="companyPhone"
              tabindex="-1"
              ><h3 class="ml-16 ml-md-0">
                <v-icon medium color="green lighten-1"> mdi-whatsapp </v-icon
                ><span class="d-none d-md-inline">Contact Us</span>
              </h3></a
            >
          </v-col>
        </v-row>

        <v-row class="mt-n4 mt-md-5 mb-0 mb-md-8" v-if="this.items != null">
          <v-col cols="12" md="3" class="d-none d-md-block"></v-col>
          <v-col cols="12" md="6" align="center">
            <v-carousel
              cycle
              height="auto"
              hide-delimiter-background
              :show-arrows="false"
            >
              <!-- <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.path"
                contain
              >
              </v-carousel-item> -->

              <v-carousel-item v-for="(item, i) in items" :key="i" eager>
                <v-img :src="item.path" eager class="banner" />
              </v-carousel-item>
            </v-carousel>
            <v-sheet color="white" elevation="4" class="pa-5 pt-9 mt-md-0 mt-n5"
              ><h2
                class="font-weight-bold text-h6 text-md-h4"
                style="
                   {
                    color: black;
                  }
                "
                v-html="header"
              ></h2>
              <br />
              <p
                class="px-8 text-body-2 text-md-subtitle-1"
                :style="{ color: descriptionTextColor }"
                v-html="description"
              ></p
            ></v-sheet>
          </v-col>
          <v-col cols="12" md="3" class="d-none d-lg-block"></v-col>
        </v-row>

        <!-- <v-row class="my-4">
          <v-col cols="12" md="4" class="d-none d-md-block"></v-col>
          <v-col cols="12" md="4" align="center">
            <v-sheet color="white" elevation="4" class="pa-5"
              ><h2
                class="font-weight-bold text-md-h4"
                style="{ color: black }"
                v-html="header"
              ></h2>
              <br />
              <p class="px-8">
                <span
                  :style="{ color: descriptionTextColor }"
                  v-html="description"
                >
                </span></p
            ></v-sheet>
          </v-col>
          <v-col cols="12" md="4" class="d-none d-lg-block"></v-col>
        </v-row> -->

        <Content
          v-if="this.companyIndustry == 'F&B' && this.company_found == true"
        />
        <otherService
          v-if="this.companyIndustry != 'F&B' && this.company_found == true"
        />

        <div class="foot">
          <v-row>
            <v-col cols="12" md="6" align="center">
              <p :style="{ color: headerTextColor }">
                Copyright &copy; 2021 |
                <a
                  href="https://web.ebooking.today/"
                  :style="{ color: headerTextColor }"
                >
                  E-Booking
                </a>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-main>
  </v-app>
</template>


<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import otherService from "@/components/otherService";
import Content from "@/components/Content";

export default {
  name: "Home",

  components: {
    Content,
    otherService,
  },
  data: () => ({
    domain: BASEURL,
    company_id: "",
    companyIndustry: "",
    url: "",
    company_name: "",
    company_found: false,
    color1: "",
    color2: "",
    backgroundHeight: "104%",
    angle: "120",
    companyContact: "",
    headerTextColor: "",
    descriptionTextColor: "",
    logo: "",
    items: [],
    header: "",
    description: "",
  }),

  computed: {
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    },

    imagePath() {
      return "https://ebooking.today/merchant_logo/" + this.logo;

      
    },
    companyPhone() {
      return "https://api.whatsapp.com/send?phone=+6" + this.companyContact;
    },
  },
  created() {
    this.url = this.$route.params.name;
    this.findCompanyByName();
  },
  watch: {},
  methods: {
    findCompanyByName() {
      const params = new URLSearchParams();
      params.append("getCompanyByURL", "done");
      params.append("url", this.url);
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyIndustry = response.data.company[0].industry;
            this.company_found = true;
            this.company_id = response.data.company[0].company_id;
            this.getCompanyContact();
            this.header = response.data.company[0].header;
            this.description = response.data.company[0].description;
            this.logo = response.data.company[0].logo;
            this.items = eval(response.data.company[0].banner);
            this.company_name = response.data.company[0].name;
            this.color1 = JSON.parse(response.data.company[0].color).color1;
            this.color2 = JSON.parse(response.data.company[0].color).color2;
            this.headerTextColor = JSON.parse(
              response.data.company[0].color
            ).headerTextColor;
            this.descriptionTextColor = JSON.parse(
              response.data.company[0].color
            ).descriptionTextColor;
          } else {
            window.location.replace("https://web.ebooking.today/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyContact() {
      const params = new URLSearchParams();
      params.append("getAdmin", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyContact = response.data.user[0].contact;
          } else {
            console.log("no admin found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}

.foot {
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  overflow: hidden;
}
/* .banner {
  background-size: cover;
  background-position: center;
} */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .banner {
    height: 250px;
    background-size: cover;
    background-position: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .banner {
    height: 526px;
    background-size: cover;
    background-position: center;
  }
}


</style>
